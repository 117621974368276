import React from 'react';
import ReactDOM from 'react-dom';

import Matrix from './Matrix.js';

ReactDOM.render(
  <div className="App">
    <Matrix />
  </div>,
  document.getElementById('root')
)
