import React from 'react';

import './matrix.css';

class QuadrantTaskList extends React.Component {
  state = {
      tasks: []
  };

  componentDidMount() {
    fetch("http://localhost/dev/php-markdown-metadata/api_matrix.php?quadrantnumber=" + this.props.quadrantnumber + "&")
        .then(res => res.json())
        .then(res => {
            this.setState({
                tasks: res
            });
        });
  }

  render() {
    return (
        <div>
          {this.state.tasks.map(item => (
              <div class="task">
                  <div class="tasklist">{item.folder}</div>
                  <div class="taskname"><input type="checkbox" />{item.subtitle_text} - {item.title}</div>
              </div>
          ))}
        </div>
    );
  }
}

function Quadrant(props) {
  return (
    <div class="quadrant">
      <div class={"quadranttitle quadranttitle"+props.quadrantnumber}>
        {
          props.quadrantnumber == 1 ? "◍ Urgent & Important #do-it" : 
          props.quadrantnumber == 2 ? "◑ Not Urgent & Important #schedule-it" : 
          props.quadrantnumber == 3 ? "◐ Urgent & Unimportant #delegate-it" : 
          props.quadrantnumber == 4 ? "◔ Not Urgent & Unimportant #delete-it" : ""
        }
      </div>
      <QuadrantTaskList quadrantnumber={props.quadrantnumber} />

    </div>
  );
}

export default function Matrix() {
  return (
    <div class="matrix">
      <Quadrant quadrantnumber="1" />
      <Quadrant quadrantnumber="2" />
      <Quadrant quadrantnumber="3" />
      <Quadrant quadrantnumber="4" />
    </div>
  );
}